import Logo from "../assets/logo_white.png";

import pl from "../assets/pl.png";
import gb from "../assets/us.png";
import ua from "../assets/ua.png";

function Footer() {
    return ( 
        <>
            <footer>
                <div class="container-lg">
                    <a class="navbar-brand" href="#">
                        <img src={Logo} alt="logo" loading="lazy"/>
                    </a>

                    <ul class="link-items">
                        <li class="link-item">
                            <a class="nav-link active" aria-current="page" href="#benefits">Benefits</a>
                        </li>
                        <li class="link-item">
                            <a class="nav-link" href="#how-it-works">How it works</a>
                        </li>
                        <li class="link-item">
                            <a class="nav-link" href="#earnings">Earnings</a>
                        </li>
                        <li class="link-item">
                            <a class="nav-link" href="#registration">Application</a>
                        </li>
                    </ul>

                    <ul className="switchers">
                        <li className="switcher">
                            <a href="/gb"><img src={gb} /></a>
                        </li>
                        <li className="switcher slash">
                            <small>/</small>
                        </li>
                        <li className="switcher">
                            <a href="./"><img src={pl} /></a>
                        </li>
                        <li className="switcher slash">
                            <small>/</small>
                        </li>
                        <li className="switcher">
                            <a href="/ua"><img src={ua} /></a>
                        </li>
                    </ul>

                </div>

                <hr />

                <div className="container-lg flex-box">
                    <div className="copyright">
                        © All rights reserved, 2021 | tylkofans.pl
                    </div>
                    <div className="copyright">
                        <a href="https://wolfpack-entertainment.net/">Wolfpack-entertainment.net</a>
                    </div>
                </div>
            </footer>
        </>
     );
}

export default Footer;