function CalendarForm({lang}) {
    return ( 
        <>
            <div className="calendar-form container" id="registration">
                <div className="apply-now">
                    <h1>
                        {lang.applynow.heading}
                    </h1>
                    <div className="arrow">
                        <div className="line-full-width"></div>
                    </div>
                    <p>{lang.applynow.formtext}</p>
                </div>

                <div className="iframe">
                    <iframe title="contact" src="https://calendly.com/contact-qco/15min"></iframe>
                </div>

                
                
                <h2 className="contact-heading">
                    {lang.applynow.subheading}
                </h2>
                <div className="contact-data">
                    <h2>
                        <span className="light">
                            <address>
                                <a href="mailto:kontakt@tylkofans.pl">kontakt@tylkofans.pl</a>
                            </address>
                        </span>
                    </h2>

                    <h2>
                        <span className="light">
                            <address>
                                <a href="tel:881023203">+48 881 023 203</a>
                                &nbsp;&nbsp;<a href="https://api.whatsapp.com/send?phone=48881023203"><i class="bi bi-whatsapp"></i></a>
                                &nbsp;
                                <a href="https://www.facebook.com/profile.php?id=61569288851617"><i class="bi bi-facebook" style={{color: "#006bff"}}></i></a>
                            </address>
                        </span>
                    </h2>
                </div>

                <div className="subtext" dangerouslySetInnerHTML={{ __html: lang.applynow.undercontacts }} />
                
            </div>
        </>
     );
}

export default CalendarForm;