import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Earnings({ lang }) {
    let locale = lang.earnings;

    const data = [
        { month: 'Month 1', earnings: 8000 },
        { month: 'Month 2', earnings: 14000 },
        { month: 'Month 3', earnings: 28000 },
        { month: 'Month 4', earnings: 36000 },
        { month: 'Month 5', earnings: 52000 },
        { month: 'Month 6', earnings: 68000 },
    ];

    const formatYAxis = (tickItem) => {
        return `${tickItem}`;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #cccccc' }}>
                    <p className="label">{`${label} : ${payload[0].value} zł`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <div className="earnings" id="earnings">
                <div className="container">
                    <h2 className="earn-title">
                        <center>{locale.heading}</center>
                    </h2>
                    <div className="row">
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart
                                data={data}
                                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                            >
                                <defs>
                                    <linearGradient id="colorEarnings" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#30B4FF" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#30B4FF" stopOpacity={0.1} />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" tickMargin={10} />
                                <YAxis
                                    domain={[0, 8000, 68000]}
                                    ticks={[0, 8000, 14000, 28000, 36000, 52000, 68000]}
                                    tickMargin={10}
                                    tickFormatter={formatYAxis}
                                />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                                <Area
                                    type="monotone"
                                    dataKey="earnings"
                                    stroke="#30B4FF"
                                    fillOpacity={1}
                                    fill="url(#colorEarnings)"
                                    strokeWidth={3}
                                    dot={{ stroke: '#30B4FF', strokeWidth: 2, r: 6 }}
                                    activeDot={{ r: 8 }}
                                    name="Earnings (zł)"
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <div
                        className="under-earning"
                        dangerouslySetInnerHTML={{ __html: locale.important }}
                    />
                </div>
            </div>
        </>
    );
}

export default Earnings;