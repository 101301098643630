import { useEffect, useState } from "react";
import axios from "axios";

import Header from "./components/header";
import MainScreen from "./components/main-screen";
import Benefits from "./components/benefits"
import HowItWorks from "./components/how-it-works";
import CalendarForm from "./components/form-calendar";
import Footer from "./components/footer";
import Earnings from "./components/earnings";
import Popup from "./components/popup";
import Video from "./components/video";

import localeGB from './locale/gb.json';
import localePL from './locale/pl.json';
import localeUA from './locale/ua.json';
import GetPDF from "./components/get-pdf";
import Blog from "./components/blog";


function Main({lang}) {

    let [popup, popupStatus] = useState(false);
    let [currentLang, setCurrentLang] = useState(localePL);
    let [clientData, setClientData] = useState({
        clientName: "",
        dateTime: ""
    })

    let updatePopupOnClick = () => {
        popupStatus(false);
    };

    useEffect(() => {
        switch(lang){
            case "gb":
                setCurrentLang(localeGB);
                break;
            case "ua":
                setCurrentLang(localeUA);
                break;
            case "pl":
                setCurrentLang(localePL);
                break;
            default: 
                setCurrentLang(localePL);
        }
    }, []);


    useEffect(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        const isSubmitted = params.get('isSubmitted');

        if(isSubmitted){
            popupStatus(true);

            const clientName = params.get('invitee_full_name');
            const encodedTime = params.get('event_start_time');

            const decodedTime = decodeURIComponent(encodedTime);
            const eventDate = new Date(decodedTime);

            const options = {
                year: 'numeric', 
                month: 'long', 
                day: 'numeric', 
                hour: 'numeric', 
                minute: 'numeric', 
                second: 'numeric',
                timeZoneName: 'short'
            };

            const dateTime = eventDate.toLocaleString(undefined, options);

            setClientData({
                clientName: clientName,
                dateTime: dateTime
            })
        }else{
            popupStatus(false);
        }
    }, []);

    return (
        <>
            <Popup lang={currentLang} status={popup} updatePopupOnClick={updatePopupOnClick} clientData={clientData} />
            <Header lang={currentLang} />
            <MainScreen lang={currentLang} /> 
            <Video lang={currentLang} />
            <GetPDF lang={currentLang} /> 
            <Benefits lang={currentLang} />
            <HowItWorks lang={currentLang} />
            <Earnings lang={currentLang} />
            <CalendarForm lang={currentLang} />
            <Blog lang={currentLang} />
            <Footer lang={currentLang} />    
        </> 
    );
}

export default Main;