import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';

const GetPDF = ({lang}) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  let locale = lang.getpdf;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage(null);
  
    try {
      console.log('Sending request with data:', { email, language: lang.lang });
      const response = await axios.get('/backend/get-pdf.php', {
        params: {
          email: email,
          language: lang.lang
        }
      });
      console.log('Response received:', response.data);
      setMessage({ type: 'success', text: locale.msgsuccess });
    } catch (error) {
      console.error('Error in API call:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
        setMessage({ type: 'danger', text: error.response.data.message || locale.msgfail });
      } else {
        setMessage({ type: 'danger', text: locale.msgfail });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="get-pdf-container container">
        <div className="row">
            <div className="col-12 col-md-8 full-w-900">
                <h2>{locale.leaveemail}</h2>
                <h2 className='secondary-header'>{locale.secondaryheader}</h2>
                <div className="form-wrapper">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail">
                        <Form.Control
                            type="email"
                            placeholder={locale.email}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? 'Sending...' : locale.sendpdf}
                        </Button>
                    </Form>
                </div>
                {message && (
                    <Alert variant={message.type} className="mt-3">
                    {message.text}
                    </Alert>
                )}
            </div>
            <div className="col-0 col-md-4 hide-on-900">
                <div className="bg-image">

                </div>
            </div>
      </div>
    </div>
  );
};

export default GetPDF;