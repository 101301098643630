import React from 'react';

const Video = ({ src, autoPlay = false, controls = true, width = '100%', height = 'auto' }) => {
  /*
  return (
    <div className="video-container container">
      <video
        src={src}
        autoPlay={autoPlay}
        controls={controls}
        width={width}
        height={height}
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
  */
};

export default Video;