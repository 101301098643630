import { BrowserRouter as Router, Route, Routes, Link, Outlet } from 'react-router-dom';
import Main from './Main';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main lang="pl" />} />
        <Route path="/gb" element={<Main lang="gb" />} />
        <Route path="/ua" element={<Main lang="ua" />} />
      </Routes>
    </Router>
  );
}

export default App;
