import React from "react";
import { useRef, useEffect } from 'react';

import video1 from "../assets/v1.mp4"
import video2 from "../assets/v2.mp4"
import video3 from "../assets/v3.mp4"

import gif1 from "../assets/v1.gif"
import gif2 from "../assets/v2.gif"
import gif3 from "../assets/v3.gif"

let MainScreen = ({lang}) => {

    let locale = lang.main;

    return (
        <>
            <div className="container main-block">
                
                <div className="row d-md-none d-none d-lg-flex d-xl-flex d-xxl-flex">

                    <div className="col-12 col-md-12 col-lg-5">
                        <h1 className="main-heading">{locale.heading}</h1>
                        <p  className="main-text">
                        {locale.desc}
                        </p>
                        <button className="main-button">
                            <a href="#registration">
                                {locale.btn}
                            </a>
                        </button>
                    </div>

                    <div className="col-12 col-md-12 col-lg-7">
                        <div className="main-image-container">
                            <div className="video-wrapper video-wrapper-1">
                                <video className="video video-1" src={video1} width="220" autoPlay muted loop>
                                    <source src={video1} type="video/mp4" />
                                </video>
                            </div>

                            <div className="video-wrapper video-wrapper-2">
                                <video className="video video-2" src={video2} width="220" autoPlay muted loop>
                                    <source src={video2} type="video/mp4" />
                                </video>
                            </div>

                            <div className="video-wrapper video-wrapper-3">
                                <video className="video video-3" src={video3} width="220" autoPlay muted loop>
                                    <source src={video3} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>

                </div>







                <div className="row d-lg-none d-xl-none d-xxl-none">

                    <div className="col-12 col-md-12 col-lg-7">
                        <div className="main-image-container">
                            <div className="gif">
                                <img src={gif1} alt="" loading="lazy"/>
                            </div>

                            <div className="gif">
                                <img src={gif2} alt="" loading="lazy"/>
                            </div>

                            <div className="gif">
                                <img src={gif3} alt="" loading="lazy"/>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-5">
                        <h1 className="main-heading">{locale.heading}</h1>
                        <p  className="main-text">
                        {locale.desc}
                        </p>
                        <button className="main-button">
                            <a href="#registration">
                                {locale.btn}
                            </a>
                        </button>
                    </div>

                </div>


            </div>
        </>
    );
}

export default MainScreen;