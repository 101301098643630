import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import model from '../assets/benefit-3.jpeg'

const Benefits = ({lang}) => {

    let locale = lang.benefits;
    console.log(lang);

    return (
        <>
            <div className="benefits-block" id="benefits">
                <center>
                    <h2><b>{locale.heading}</b></h2>
                    <h2 className='secondary-header'>{locale.subheading}</h2>
                </center>
                <div className="container">

                    <div className="card-item">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-4 img-wrapper">
                            </div>
                            <div className="col-12 col-md-12 col-lg-8 flex-items-container">
                                <div className="row benefits-items">
                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading1}</b>
                                            {locale.itemcontent1}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading2}</b>
                                            {locale.itemcontent2}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading3}</b>
                                            {locale.itemcontent3}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading4}</b>
                                            {locale.itemcontent4}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading5}</b>
                                            {locale.itemcontent5}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading6}</b>
                                            {locale.itemcontent6}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading7}</b>
                                            {locale.itemcontent7}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading8}</b>
                                            {locale.itemcontent8}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="item-inside">
                                            <b>{locale.itemheading9}</b>
                                            {locale.itemcontent9}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Benefits;
